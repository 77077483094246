



























import { Component, Vue ,Ref } from 'vue-property-decorator'
import { SidebarPlugin } from '@syncfusion/ej2-vue-navigations';
import { ListViewPlugin } from '@syncfusion/ej2-vue-lists';
 import { enableRipple } from '@syncfusion/ej2-base';
import sidebaritem from "./sidebar-item.vue";
import {User} from "@/Models/User"
import { from } from "linq-to-typescript"
    enableRipple(true);
    Vue.use(SidebarPlugin);
    Vue.use(ListViewPlugin);
@Component
export default class Layout extends Vue {
     @Ref() readonly sidebarInstance!: any
            fields= { tooltip: 'text' };
                listTemplate= function () {
                    return {
                        template: sidebaritem
                    }
                };
                enableDock= true;
                dockSize= '57px';
                width= '220px';
                target= '.main-content';
                mediaQuery= '(min-width: 600px)';
                dataList:Array<any>= [{
                        text: "Dashboard", IconCss: "fas fa-tachometer-alt", Url: "Dashboard"
                    }];
toggleClick(){
      if (this.sidebarInstance.ej2Instances.isOpen) {
                    this.sidebarInstance.hide();
                }
                else {
                    this.sidebarInstance.show();
                }
}
mounted(){
    var user:User = this.$store.state.User;
    if(from(user.roles).where(i=>i.name=="Partner").count()>0){
 this.dataList.push({ text: "Partner", IconCss: "fas fa-users-cog",  child: [
                        { text: "Student", IconCss: "fas fa-user-graduate", Url: "Partner-student", },
                        { text: "Promocode", IconCss: "fas fa-ticket-alt", Url: "Partner-code", },
                        { text: "Order", IconCss: "fas fa-receipt", Url: "Partner-order", }
                    ]});
    }
    if(from(user.roles).where(i=>i.name=="Sales").count()>0){
 this.dataList.push( { text: "Market", IconCss: "fas fa-mail-bulk",  child: [
                        { text: "Demo Class", IconCss: "fas fa-headset", Url: "market-d-class", },
                         { text: "Order", IconCss: "fas fa-receipt", Url: "sales-order", },
                        { text: "Live Class", IconCss: "fas fa-phone-square", Url: "sales-l-class", },
                        { text: "Promocode", IconCss: "fas fa-ticket-alt", Url: "sales-promocode", },
                        { text: "Feedback", IconCss: "fas fa-comment-dots", Url: "sales-feedback", },
                        { text: "Students", IconCss: "fas fa-user-graduate", Url: "sales-student", },
                    ]});
    }
  if(from(user.roles).where(i=>i.name=="School").count()>0){
 this.dataList.push(  { text: "School", IconCss: "fas fa-school",  child: [
                        { text: "Callback Request", IconCss: "fas fa-phone-alt", Url: "school-call-request", }
                    ]});
    }
    if(from(user.roles).where(i=>i.name=="Teacher").count()>0){
 this.dataList.push(  { text: "Teacher", IconCss: "fas fa-user-tie",  child: [
                            { text: "Students", IconCss: "fas fa-user-graduate", Url: "teacher-enroll", },
                            { text: "Demo Class", IconCss: "fas fa-headset", Url: "teacher-demo-class", },
                            { text: "Live Class", IconCss: "fas fa-phone-square", Url: "teacher-live-class", },
                            { text: "Assignment", IconCss: "fas fa-file-archive", Url: "teacher-assignment", },
                            { text: "Feedback", IconCss: "fas fa-comment-dots", Url: "teacher-feedback", },
                    ]});
    }
    if(from(user.roles).where(i=>i.name=="RoleManager").count()>0){
 this.dataList.push(  { text: "Role Manager", IconCss: "fas fa-user-shield",  child: [
                            { text: "Users", IconCss: "fas fa-user-lock", Url: "role-user", },
                    ]});
    }
    if(from(user.roles).where(i=>i.name=="TeacherManager").count()>0){
 this.dataList.push(  { text: "Teacher Manager", IconCss: "fas fa-user-cog",  child: [
                            { text: "Teachers", IconCss: "fas fa-user-tie", Url: "teacher-admin-list", },
                            { text: "Courses", IconCss: "fas fa-book", Url: "teacher-admin-course", },
                            { text: "EnrollCourse", IconCss: "fas fa-user-graduate", Url: "teacher-admin-student-list", },
                    ]});
    }
     if(from(user.roles).where(i=>i.name=="ContentManager").count()>0){
 this.dataList.push(  { text: "Content", IconCss: "fas fa-user-cog",  child: [
                           
                            { text: "Content", IconCss: "fas fa-book", Url: "content-data", },
                            { text: "Question", IconCss: "fas fa-user-graduate", Url: "content-question", },
                    ]});
    }
   this.dataList.push({
                        text: "Logout", IconCss: "fas fa-sign-out-alt", Url: "Logout"
                    });
}
}
