<template>
    <router-link :to="{name:data.Url}" v-if="!data.hasOwnProperty('child')">
        <div class="formatRoute">
            <p style="margin:0px !important">
                <span v-bind:class="data.IconCss" style="padding:0 10px" />
                {{data.text}}
            </p>
        </div>

    </router-link>
    <div class="formatRoute" v-else>
        <p style="margin:0px !important">
            <span v-bind:class="data.IconCss" style="padding:0 10px" />
            {{data.text}}
            <span class="e-icons e-icon-collapsible" style="float:right;padding:0 5px" />
        </p>
    </div>

</template>
<script>
    
    export default {
        data() {
            return {
                data: {}
            };
        },
        mounted() {
           
        }
    };
</script>
<style>
    a:link {
        text-decoration: none;
        color:black;
    }

    a:visited {
        text-decoration: none;
        color: black;
    }

    a:hover {
        text-decoration: none;
        color: black;
    }

    a:active {
        text-decoration: none;
        color: black;
    }
</style>
